.humbleness {
  width: 930px;
  & > img {
    display: block;
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .humbleness {
    width: 100%;
  }
}
