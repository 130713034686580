.courage {
  max-width: 690px;
  text-align: center;
  &__title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: #292929;
  }
  &__description {
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    color: #2e2f30;
  }
}

@include media-breakpoint-down(md) {
  .courage {
    width: 100%;

    &__title {
      line-height: 40px;
    }
  }
}
