.simpleness {
  display: flex;
  margin: 0 -24px;
  &__item {
    width: 278px;
    margin: 0 24px;
    & > img {
      display: block;
      width: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  .simpleness {
    margin: 0 -18px;
    &__item {
      width: 200px;
      margin: 0 18px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .simpleness {
    width: 175%;
    &__item {
      width: 50%;
    }
  }
}
