.lattice {
  $self: &;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 85px;
  margin-bottom: 98px;
  &__item {
    width: 316px;
    cursor: pointer;
    background-color: #fbfbfb;
    border: 2px solid #e8e8e8;
    &__one {
      padding: 25px;
      border-bottom: 2px solid #e8e8e8;
      &__text1 {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;
        color: #000;
        &__tag {
          padding: 2px 10px;
          margin-left: 9px;
          font-size: 12px;
          font-weight: 500;
          line-height: 17px;
          color: #fff;
          background-color: #f93;
          @include border-radius(2px);
        }
      }
      &__text2 {
        font-size: 15px;
        font-weight: 300;
        line-height: 28px;
        color: #444647;
      }
    }
    &__two {
      padding: 25px;
      &__text {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        color: #2e2e2e;
        text-align: center;
      }
    }

    &--active {
      background-color: #f5ffff;
      border: 2px solid #008d8e;
      #{ $self }__item {
        &__two {
          &__text {
            color: #00a4a5;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .lattice {
    margin-top: 60px;
    margin-bottom: 0;

    &__item {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
