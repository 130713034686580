.navbar {
  height: 77px;
  //overflow: hidden;
  background-color: #fff;
  box-shadow: 0 -2px 3px 3px rgba(234, 234, 234, .5);

  &--fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }

  &__body {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__navs {
    @extend .list-unstyled;
    display: flex;
    flex-shrink: 0;
    margin-bottom: 0;

    & > li {
      flex-shrink: 0;
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }

      & > a {
        display: block;
        font-size: 15px;
        font-weight: 500;
        line-height: 1;
        color: #000;

        &:hover {
          color: #178182;
        }
      }
    }
  }

  &__logo {
    flex: 1;
    flex-shrink: 0;
    margin-right: 30px;
    margin-left: 30px;
    & > a {
      display: block;
      & > img {
        display: block;
        width: 124px;
        height: 16px;
        margin: auto;
      }
    }
  }

  &__product-logo {
    flex-shrink: 0;
    margin-right: 30px;
    & > a {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 1;
      color: #000;

      &:hover {
        color: #178182;
      }

      & > img {
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
  }

  &__toggle {
    display: none;
  }

  &__toggle-menu {
    display: none;
  }
}

.has-navbar-fixed {
  padding-top: 77px;
}

@include media-breakpoint-down(md) {
  .navbar {
    $self: &;

    &__navs {
      display: none;
    }

    &__product-logo {
      width: 25px;
      height: 25px;
      margin-right: 0;
      overflow: hidden;

      & > a {
        display: block;
      }
    }

    &__toggle {
      display: block;
      flex-shrink: 0;
      width: 25px;
      height: 25px;
      padding: 0;
      background-color: transparent;
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"));
      background-size: contain;
      border: none;

      &__menu {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 100%;
        display: block;
        padding: 15px 0;
        margin: 0;
        list-style: none;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);

        & > li > a {
          display: block;
          padding: 15px;
          font-size: 15px;
          color: #292929;
          text-align: left;
          white-space: nowrap;
        }
      }

      &:focus > &__menu {
        left: 50%;
      }
    }
  }
}
