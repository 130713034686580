.doubt {
  $self: &;

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 48px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &__comment {
    font-size: 12px;
    font-weight: 300;
    line-height: 28px;
    color: #a0a0a0;
    text-align: center;
  }

  &--primary {
    #{ $self }__btn {
      background: url("/assets/ui/doubt-primary.png") no-repeat;
      background-size: contain;
    }
  }

  &--sky {
    #{ $self }__btn {
      background: url("/assets/ui/doubt-sky.png") no-repeat;
      background-size: contain;
    }
  }

  &--umbra {
    #{ $self }__btn {
      background: url("/assets/ui/doubt-umbra.png") no-repeat;
      background-size: contain;
    }
  }

  &--disable {
    #{ $self }__btn {
      background: url("/assets/ui/doubt-disable.png") no-repeat;
      background-size: contain;
    }
  }
}

.doubt-group {
  display: flex;
  margin: 0 -20px;

  & > .doubt {
    flex-shrink: 0;
    margin: 0 20px;
  }
}

.doubt-icon {
  flex-shrink: 0;
  margin-right: 10px;
  &--android {
    width: 15px;
    height: 18px;
    background: url("/assets/ui/doubt-icon-android.svg") no-repeat;
    background-size: contain;
  }
  &--windows {
    width: 15px;
    height: 15px;
    background: url("/assets/ui/doubt-icon-windows.svg") no-repeat;
    background-size: contain;
  }
  &--app-store {
    width: 19px;
    height: 16px;
    background: url("/assets/ui/doubt-icon-app-store.svg") no-repeat;
    background-size: contain;
  }
  &--apple {
    width: 15px;
    height: 19px;
    background: url("/assets/ui/doubt-icon-apple.svg") no-repeat;
    background-size: contain;
  }
}

@include media-breakpoint-down(md) {
  .doubt-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin: -18px -15px;

    & > .doubt {
      flex-shrink: 0;
      margin: 18px 15px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .doubt {
    &__btn {
      width: 120px;
      height: 38.4px;
      font-size: 12px;
    }

    &__comment {
      font-size: 9.6px;
      line-height: 22.4px;
    }
  }
  .doubt-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin: -14.4px -12px;

    & > .doubt {
      flex-shrink: 0;
      margin: 14.4px 12px;
    }
  }
  .doubt-icon {
    flex-shrink: 0;
    margin-right: 8px;
    &--android {
      width: 12px;
      height: 14.4px;
    }
    &--windows {
      width: 12px;
      height: 12px;
    }
    &--app-store {
      width: 15.2px;
      height: 12.8px;
    }
    &--apple {
      width: 12px;
      height: 15.2px;
    }
  }
}
