.upgrade {
  margin-right: -10px;
  margin-left: -10px;
  &__type {
    width: 150px;
  }
  &__account {
    width: 317px;
  }

  &.form-row > .col,
  &.form-row > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@include media-breakpoint-down(lg) {
  .upgrade-container {
    width: 100%;
  }
  .upgrade {
    &__type {
      width: 100%;
    }
    &__account {
      width: 100%;
    }
    &__button {
      display: block;
      width: 100%;
    }
  }
}
