.tiro {
  position: relative;
  width: 910px;
  height: 634px;

  &__main {
    position: relative;
    width: 645px;
    height: 100%;
    margin: auto;

    &__phone {
      position: absolute;
      top: 0;
      left: 0;
      width: 248px;

      & > img {
        display: block;
        width: 100%;
      }
    }
    &__notebook {
      position: absolute;
      top: 133px;
      right: -70px;
      width: 578px;

      & > img {
        display: block;
        width: 100%;
      }
    }
  }

  &__annex1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 427px;

    & > img {
      display: block;
      width: 100%;
    }
  }
  &__annex2 {
    position: absolute;
    top: 89px;
    right: 0;
    width: 230px;

    & > img {
      display: block;
      width: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  .tiro {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 100%;
    //height: 634px;

    &__main {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      //width: 645px;
      width: 100%;
      height: 100%;
      margin: auto;

      &__phone {
        position: absolute;
        top: 0;
        left: 0;
        width: 38.449%;

        & > img {
          display: block;
          width: 100%;
        }
      }
      &__notebook {
        position: absolute;
        top: 20.62%;
        right: -10.852%;
        width: 89.612%;

        & > img {
          display: block;
          width: 100%;
        }
      }
    }

    &__annex1 {
      position: absolute;
      bottom: 0;
      left: -27%;
      width: 66.201%;

      & > img {
        display: block;
        width: 100%;
      }
    }
    &__annex2 {
      position: absolute;
      top: 13.798%;
      right: -20%;
      width: 35.658%;

      & > img {
        display: block;
        width: 100%;
      }
    }
  }
}
