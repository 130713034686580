.footer {
  padding-top: 54px;
  padding-bottom: 42px;
  background-color: #fafafa;
  &__copyright {
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    color: rgba(105, 105, 105, 1);
    text-align: center;
  }
}
.footer-link {
  display: block;
  & > img {
    display: block;
  }
  &--logo {
    margin-right: 40px;
    & > img {
      width: 124px;
      height: 16px;
    }
  }
  &--icon {
    margin-right: 22px;
    & > img {
      width: 36px;
      height: 36px;
    }
  }
}
.footer-link-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 27px;
  & > .footer-link {
    flex-shrink: 0;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
