.ezra {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .5);
  &--active {
    display: flex;
  }

  &__window {
    background-color: #fff;
    @include border-radius(5px);
    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      & > img {
        width: 200px;
        height: 200px;
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 20px;
    }
  }
}
