.article {
  padding: 30px 0;

  .highlight.plain {
    overflow: auto;
  }

  img {
    max-width: 100%;
  }
}
