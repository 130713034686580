.gem {
  &::before {
    display: block;
    width: 35px;
    height: 32px;
    margin-right: 12px;
    content: "";
    background-image: url("/assets/images/gem.png");
    background-size: 35px 32px;
  }
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 82px;
  margin-bottom: 72px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #008d8e;
}

@include media-breakpoint-down(md) {
  .gem {
    justify-content: flex-start;
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 22px;
    line-height: 1.5;
  }
}
