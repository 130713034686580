.pain {
  width: 100%;
  text-align: center;
  &__title {
    margin-bottom: 29px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: #2e2e2e;
  }
  &__description {
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    color: #434648;
  }
}

@include media-breakpoint-down(md) {
  .pain {
    width: 100%;
    text-align: left;

    &__title {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 1.5;
    }
  }
}
