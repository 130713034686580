.sr {
  .has-animations {
    .is-revealing {
      visibility: hidden;
    }
  }
}

html {
  height: 100%;
}
