.brave {
  display: flex;
  align-items: center;

  &__logo {
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    margin-right: 32px;
  }

  &__text {
    flex-shrink: 0;
    font-size: 48px;
    font-weight: 500;
    line-height: 1;
    color: #000;
  }
}
