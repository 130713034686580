.grass {
  padding: 0;
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  color: #9b9b9b;
  list-style: inside decimal;
}

@include media-breakpoint-down(md) {
  .grass {
    margin-top: 30px;
    margin-bottom: 30px;
    list-style: inside decimal;
    & > li {
      margin-bottom: 10px;
    }
  }
}
