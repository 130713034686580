.pie {
  &--type-1 {
    padding: 78px 0;
  }
  &--type-2 {
    margin-bottom: 60px;
  }
  &--type-3 {
    margin-bottom: 72px;
  }
  &--type-4 {
    margin-bottom: 90px;
  }
  &--type-5 {
    margin-bottom: 50px;
  }
  &--type-6 {
    margin-bottom: 140px;
  }
}

@include media-breakpoint-down(md) {
  .pie {
    &--type-1 {
      padding: 78px 0;
    }
    &--type-2 {
      margin-bottom: 60px;
    }
    &--type-3 {
      margin-bottom: 78px;
    }
    &--type-4 {
      margin-bottom: 100px;
    }
    &--type-6 {
      margin-bottom: 100px;
    }
    &--type-dec {
      margin-right: -25px;
      margin-left: -25px;
    }
  }
}

