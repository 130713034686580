/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

$grid-gutter-width: 50px !default;
$link-hover-decoration: none !default;
$font-family-sans-serif: "STHeiti", "Microsoft JhengHei", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
//@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
//@import "transitions";
//@import "dropdown";
//@import "button-group";
//@import "input-group";
//@import "custom-forms";
//@import "nav";
//@import "navbar";
//@import "card";
//@import "breadcrumb";
//@import "pagination";
//@import "badge";
//@import "jumbotron";
//@import "alert";
//@import "progress";
//@import "media";
//@import "list-group";
//@import "close";
//@import "toasts";
//@import "modal";
//@import "tooltip";
//@import "popover";
//@import "carousel";
//@import "spinners";
@import "utilities";
@import "print";

@import "custom-components/navbar";
@import "custom-components/brave";
@import "custom-components/courage";
@import "custom-components/doubt";
@import "custom-components/tiro";
@import "custom-components/simpleness";
@import "custom-components/humbleness";
@import "custom-components/alien";
@import "custom-components/footer";
@import "custom-components/first-screen";
@import "custom-components/pie";
@import "custom-components/article";
@import "custom-components/sr";
@import "custom-components/gem";
@import "custom-components/pain";
@import "custom-components/lattice";
@import "custom-components/upgrade";
@import "custom-components/grass";
@import "custom-components/error";
@import "custom-components/ezra";
@import "custom-components/eras";
